<template>
  <el-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="标题"
            prop="title"
            :rules="[ { required: true, message: '标题不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.title"
              placeholder="请输入模板名称"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="发生时间">
            <el-date-picker
              v-model="form.occurrenceTime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="案例详情">
            <EditorVue
              style="border: 1px solid rgb(204, 204, 204);"
              :content.sync="form"
            />
          </el-form-item>
        </b-col>
      </b-row>
      <b-row
        v-for="(item,index) in form.lawInfoList"
        :key="index"
      >
        <b-col md="6">
          <el-form-item
            label="涉及法律"
            :prop="'lawInfoList.' + index + '.lawCode'"
            :rules="{required: true, message: '涉及法律不能为空', trigger: 'change'}"
          >
            <el-select
              v-model="item.lawCode"
              placeholder="请选择法律"
              @change="getDetailsOptions(item)"
            >
              <el-option
                v-for="(law,k) in lawsOption"
                :key="k"
                :label="law.LawName"
                :value="law.lawCode"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col
          md="4"
        >
          <el-form-item
            class="no-label"
            :prop="'lawInfoList.' + index + '.lawDetailsId'"
            :rules="{required: true, message: '章节不能为空', trigger: 'change'}"
          >
            <el-select
              v-model="item.lawDetailsId"
              placeholder="请选择章节"
            >
              <el-option
                v-for="(chapter,k) in item.chapterOption"
                :key="k"
                :label="chapter.labelName"
                :value="chapter.lawDetailsId"
              />
            </el-select>
          </el-form-item>

        </b-col>
        <b-col
          md="2"
          class="text-left"
        >
          <i
            v-if="index === 0"
            class="el-icon-circle-plus-outline icon-btn"
            @click="form.lawInfoList.push({ lawCode: '', lawDetailsId: '', chapterOption: [] })"
          />
          <i
            v-else
            class="el-icon-remove-outline icon-btn"
            @click="form.lawInfoList.splice(index,1)"
          />
        </b-col>
      </b-row>
    </el-form>
    <div class="text-center mt20">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import EditorVue from '@core/components/editor-vue/EditorVue'
import {
  saveOrUpdateLawCase, GetLawCaseById, GetLawOptions, GetDetailsOptions,
} from '@/api/knowledgeBase/laws.js'

export default {
  components: {
    EditorVue,
  },
  data() {
    return {
      loading: false,
      form: {
        lawInfoList: [
          { lawCode: '', lawDetailsId: '', chapterOption: [] },
        ],
        content: '',
        occurrenceTime: '',
        title: '',
        id: 0,
      },
      lawsOption: [],
      query: this.$route.query,
    }
  },
  created() {
    this.getLawOptions()
    if (this.query.id) {
      this.form.id = Number(this.query.id)
      this.getDetailById()
    }
  },
  mounted() {
    this.navActiveInit(1, '案例库', '知识库管理')
  },
  destroyed() {
    this.navActiveInit(2, '案例库', '知识库管理', '/lawCase')
  },
  methods: {
    back() {
      this.$router.push({
        path: '/lawCase',
      })
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getDetailsOptions(item) {
      GetDetailsOptions({ lawCode: item.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          item.lawDetailsId = ''
          item.chapterOption = resData.data
        }
      })
    },
    getDetailById() {
      GetLawCaseById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.lawInfoList = resData.data.lawInfoList.length > 0 ? resData.data.lawInfoList : [
            { lawCode: '', lawDetailsId: '', chapterOption: [] },
          ]
          resData.data.lawInfoList.forEach(law => {
            law.chapterOption = []
          })
          this.form = { ...resData.data }
          if (this.form.lawInfoList.length > 0) {
            this.form.lawInfoList.forEach(l => {
              GetDetailsOptions({ lawCode: l.lawCode }).then(res1 => {
                const resData1 = res1.data
                if (resData1.code === 0) {
                  l.chapterOption = resData1.data
                }
              })
            })
          }
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const submitData = {
            lawInfoList: [],
            content: this.form.content,
            occurrenceTime: this.form.occurrenceTime,
            title: this.form.title,
            id: this.form.id ? Number(this.form.id) : 0,
          }
          this.form.lawInfoList.forEach(item => {
            if (item.lawCode) {
              const lawDetailsId = item.lawDetailsId ? item.lawDetailsId : ''
              submitData.lawInfoList.push({
                lawCode: item.lawCode,
                lawDetailsId,
              })
            }
          })
          saveOrUpdateLawCase(submitData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
 .icon-btn{
   position: relative;
   top: 10px;
   font-size: 20px;
   cursor: pointer;
 }
 .no-label{
   .el-form-item__content{
     margin-left: 0 !important;
   }
 }
</style>
